import TwitterIcon from "@material-ui/icons/Twitter";
import CopyrightIcon from "@material-ui/icons/Copyright";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

//Assets
import Backstage from "../../assets/photos/midSection/Backstage.jpg";

const Footer = () => {
  const d = new Date();

  return (
    <div className="footer">
      <div className="footer__left">
        <div className="footer__left--paragraph">
          <p>
            We are currently working on rebuilding the website, if you would
            like to get in touch please send us an email or a dm on twitter and
            will be in touch ASAP.{" "}
          </p>
        </div>
        <div className="footer__left--social">
          <a
            href="https://twitter.com/beautyspboudoir"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon style={{ fontSize: "3rem" }} />
          </a>
          <a href="mailto: beautifulspiritboudoir@protonmail.com">
            <MailOutlineIcon style={{ fontSize: "3rem" }} />
          </a>
        </div>

        <div className="footer__left--copyright">
          Beautiful Spirit <CopyrightIcon /> {d.getFullYear()}
        </div>
        <div className="footer__left--copyright">
          <p></p>
        </div>
      </div>
      <div className="footer__right">
        <div className="footer__right--paragraph">
          <p>
            {" "}
            Shooting with Beautiful Spirit is a very comprehensive experience
            from start to finish. It is 100% a collaboration and we work closely
            as a team to visually depict your brand in a new and authentic way.
          </p>
        </div>
        <div className="footer__right--image">
          <img src={Backstage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
