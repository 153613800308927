//Assets
import Mid5 from "../assets/photos/midSection/mid-5.jpg";

const CommingSoonScreen = () => {
  return (
    <div className="commingSoonScreen">
      <div className="commingSoonScreen__logo">
        <h1>Beautiful Spirit</h1>
      </div>
      <div className="commingSoonScreen__title">
        <h2>Transform your content</h2>
      </div>

      <div className="commingSoonScreen__commingSoon">
        <h4>COMMING SOON</h4>
        <h5>We are tidying things up</h5>
      </div>

      <div className="commingSoonScreen__imgContainer">
        <img src={Mid5} alt="" />
      </div>
    </div>
  );
};

export default CommingSoonScreen;
