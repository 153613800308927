//Styles
import "./sass/main.scss";

//React Router
import { BrowserRouter, Switch, Route } from "react-router-dom";

//Components
import CommingSoonScreen from "./screens/CommingSoonScreen";
import Footer from "./Components/CommingSoon/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route path="/">
            <CommingSoonScreen />
            <Footer />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
